import axios from 'axios';
import type { UserRespDto } from '../models/user'

const GET_SELF_USER_URL = process.env.REACT_APP_API_ENDPOINT+'/v1/user/self';

const UserApis = () => {

    const getSelfUserApi = async (apiKey: string) => {

        const config = {
          headers: {
            'Accept': 'application/json',
            "Authorization": "Bearer " + apiKey
            }
          };
    
        const response = await axios.get<UserRespDto>(GET_SELF_USER_URL, config);
        return response;
      }

      return {  
        getSelfUserApi
      }

}

export default UserApis;