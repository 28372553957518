import axios from 'axios';
import type { ARMReqDto } from '../models/arm'
import type { ARMRespDto } from '../models/arm'

const GET_ACTIVE_WA_ARM_URL = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v2/business/${businessId}/autoresponse?order=DEFAULT&fba=true`;
const CREATE_WA_ARM_URL = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/autoresponse?order=DEFAULT`;
const ACTIVATE_WA_ARM = (businessId: number, armId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/wa/autoresponse/${armId}/order/DEFAULT`;

const ARMApis = () => {
    
    const createWaArmApi = async (apiKey: string, businessId: number, armReqDto: ARMReqDto) => {

        const config = {
            headers: {
              "Content-Type": "application/json",  
              "Authorization": "Bearer " + apiKey
            }
          };

        const data = JSON.stringify(armReqDto);
        const response = await axios.post<ARMRespDto>(CREATE_WA_ARM_URL(businessId), data, config);
        return response;
    }
    
    const getWaArmApi = async (apiKey: string, businessId: number) => {
        
        const config = {
            headers:{
              "Authorization": "Bearer " + apiKey
            }
          };

        const response = await axios.get<Array<ARMRespDto>>(GET_ACTIVE_WA_ARM_URL(businessId), config);
        return response;
    }
  
  const activateWaArmApi = async (apiKey: string, businessId: number, armId: number) => {
  
    const config = {
      headers: {
        "Authorization": "Bearer " + apiKey
      }
    }

    const response = await axios.post(ACTIVATE_WA_ARM(businessId, armId), null, config);
    return response;

  }
          

    return {
      createWaArmApi,
      getWaArmApi,
      activateWaArmApi
    }
}

export default ARMApis;