/*
    Base level class to interact with local storage
*/
const LocalStorage = () => {

    // method to write into storage
    async function write(key: string, value: string) {
        
        // set Item in key-value format
        localStorage.setItem(key, value);
        
    }

    // method to read from storage
    async function read(key: string):Promise<string | null> {

        // get value for key
        const value = localStorage.getItem(key);

        if (value !== undefined) {
            // return the value
            return value;
        } else {
            // return empty string if undefined
            return '';
        }

    }

    return {
        write,
        read
    }

}

export default LocalStorage;