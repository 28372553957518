import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { screenMap } from "../navigation/screenMap";

import "../css/header.css";

function Header() {
  const navigate = useNavigate();

  // logout function
  const logout = () => {
    localStorage.clear();
    navigate(screenMap.Login);
  };

  return (
    <div className="header-flex">
      <div className="header-logo">
        <h1 className="logo-text"> Callsy </h1>
      </div>
      <div className="header-links-flex">
        <Link className="header-links" to={screenMap.Login}>
          Callsy Login
        </Link>
        <Link className="header-links" to={screenMap.CallsyWa}>
          WA Setup
        </Link>
        <Link className="header-links" to={screenMap.WaMsg}>
          WA Message
        </Link>
      </div>
      <div className="header-logout">
        <form onSubmit={logout}>
          <input className="logout-button" type="submit" value="Logout" />
        </form>
      </div>
    </div>
  );
}

export default Header;
