import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { screenMap } from "./screenMap";
import {
  Header,
  Footer,
  HomeView,
  LoginView,
  CallsyWaView,
  WaMsgView,
} from "../views";

import "../css/container.css";

const AppContainer = () => {
  return (
    <BrowserRouter>
      <div className="flex-main-container">
        <div className="row-header">
          <Header />
        </div>
        <div className="row-body">
          <div className="row-body-left"></div>
          <div className="row-body-center">
            <Routes>
              <Route path="/" element={<HomeView />} />
              <Route path={screenMap.Login} element={<LoginView />} />
              <Route path={screenMap.CallsyWa} element={<CallsyWaView />} />
              <Route path={screenMap.WaMsg} element={<WaMsgView />} />
            </Routes>
          </div>
          <div className="row-body-right"></div>
        </div>
        <div className="row-footer">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AppContainer;
