import axios from 'axios';
import type { GenerateOtpReqDto, VerifyOtpReqDto, VerifyOtpRespDto } from '../models/login'

const GENERATE_OTP_URL = process.env.REACT_APP_API_ENDPOINT+'/v1/session/otp/generate';
const VERIFY_OTP_URL = process.env.REACT_APP_API_ENDPOINT+'/v1/session/otp/verify';

const LoginApis = () => {

  const generateOtpApi = async (generateOtpReqDto: GenerateOtpReqDto) => {

    const config = {
      headers: {
          "Content-Type": "application/json"
        }
      };

    const data = JSON.stringify(generateOtpReqDto);
    const response = await axios.post<void>(GENERATE_OTP_URL, data, config);
    return response;
  }

  const verifyOtpApi = async(verifyOtpReqDto: VerifyOtpReqDto) => {

    const config = {
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
      }
    };

    const data = JSON.stringify(verifyOtpReqDto);
    const response = await axios.post<VerifyOtpRespDto>(VERIFY_OTP_URL, data, config);
    return response;

  }

  return {
    generateOtpApi,
    verifyOtpApi
  }

}


export default LoginApis;