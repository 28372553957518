import { useState, useEffect } from "react";
import { ARMReqDto, ARMButtonReqDto, ARMRespDto } from "../models/arm";
import { ARMApis } from "../apis";
import { AuthSession } from "../helper";

const WaMsgModel = () => {
    
    const [isProgressFlag, setIsProgressFlag] = useState<boolean>(false);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [activeARM, setActiveARM] = useState<ARMRespDto>();
    const [armMessage, setArmMessage] = useState<string>("");
    const [armButton1, setArmButton1] = useState<string>("");
    const [armButton2, setArmButton2] = useState<string>("");
    const [armButton3, setArmButton3] = useState<string>("");
    
    const {
        createWaArmApi,
        getWaArmApi,
        activateWaArmApi
    } = ARMApis();

    const {
        getApiKey,
        getLoggedInUser,
        getLoggedInUserBusiness
    } = AuthSession();

    useEffect(() => {
        console.log("in useEffect for WaMsgModel");

        (async () => {
            console.log("in async useEffect for WaMsgModel");

            // get api key
            const apiKey = await getApiKey();
            // get user
            const user = await getLoggedInUser();
            // get business
            const business = await getLoggedInUserBusiness();
    
            console.log(apiKey);
            console.log(business);
            console.log(user);

            {/* 
                unauthorized if:
                apiKey is not available
                user does not belong to a businses
                user is not a business owner
            */} 
            
            if (apiKey && business && user && user?.user_role === "BUSINESS_OWNER") {
                setIsAuthorized(true); // authorized
            } else {
                 setIsProgressFlag(true); // check complete
                return; //exit the function, isAuthorized is false
            }

            // get businessId
            const businessId = business?.business_id;

            // get active arm
            const responseActiveARM = getWaArmApi(apiKey, businessId);

            responseActiveARM.then((res) => {
                if (res.status == 200) {
                    // API always returns a list
                    const activeARMList = res.data;
                    // there will be only one active ARM
                    setActiveARM(activeARMList[0]);
                    // set armMessage and armButtons to empty string
                    setArmMessage("");
                    setArmButton1("");
                    setArmButton2("");
                    setArmButton3("");
                } else {
                    // for response status 204
                    // exit the function, isAuthorized is true, activeARM is undefined
                }
            }).catch((err) => {
                console.log(err);
            });

            // check complete
            setIsProgressFlag(true);
        })();
    
    }, []);

    const activateWaArm = async () => {
        
        if (!isAuthorized) {
            return; //exit the function, isAuthorized is false
        }

        if (!armMessage) {
            return; // empty message, cannot be set
        }

        // create armButton1ReqDto from armButton1 if armButton1 is not empty
        const armButton1ReqDto = armButton1 ? {
            button_text: armButton1
        } : undefined;

        // create armButton2ReqDto from armButton2 if armButton2 is not empty
        const armButton2ReqDto = armButton2 ? {
            button_text: armButton2
        } : undefined;

            
        // create armButton3ReqDto from armButton3 if armButton3 is not empty
        const armButton3ReqDto = armButton3 ? {
            button_text: armButton3
        } : undefined;

        // create array of armButtonReqDto from armButton1ReqDto, armButton2ReqDto and armButton3ReqDto
        const armButtonsReqDto: Array<ARMButtonReqDto> = new Array<ARMButtonReqDto>();
        // add armButton1ReqDto to armButtonsReqDto if armButton1ReqDto is not undefined
        if (armButton1ReqDto) {
            armButtonsReqDto.push(armButton1ReqDto);
        }
        if (armButton2ReqDto) {
            armButtonsReqDto.push(armButton2ReqDto);
        }
        if (armButton3ReqDto) {
            armButtonsReqDto.push(armButton3ReqDto);
        }
        
        // create armReqDto using armMessage, armButtonsReqDto
        const armReqDto: ARMReqDto = {
            message: armMessage,
            buttons: armButtonsReqDto
        };

        // get api key
        const apiKey = await getApiKey();
        // get business
        const business = await getLoggedInUserBusiness();

        if (!apiKey || !business) {
            return; //exit the function, apiKey or business is null
        }

        // variable for businessId
        const businessId = business.business_id;
        
        // variable for armRespDto;
        let armRespDto;

        // create arm
        const responseCreateWaApi = await createWaArmApi(apiKey, businessId, armReqDto);
        if (responseCreateWaApi.status === 200) {
            // get ARM that was created
            armRespDto = responseCreateWaApi.data;
            // do not set activeARM here. Set it after activating the ARM.
        } else {
            console.error("Error creating ARM " + responseCreateWaApi.status + " " + responseCreateWaApi.statusText);
            return; //exit the function, error creating ARM
        }

        // activate arm, by now arm creation was successful
        const responseActivateWaApi = await activateWaArmApi(apiKey, businessId, armRespDto.auto_response_message_id);
        if (responseActivateWaApi.status === 204) {
            //successfully activated ARM
            //setActiveARM here
            setActiveARM(armRespDto);
        } else {
            console.error("Error activating ARM " + responseActivateWaApi.status + " " + responseActivateWaApi.statusText);
            return; //exit the function, error activating ARM
        }

    }

    return {
        isProgressFlag,
        isAuthorized,
        activeARM,
        armMessage,
        armButton1,
        armButton2,
        armButton3,
        setArmMessage,
        setArmButton1,
        setArmButton2,
        setArmButton3,
        activateWaArm
    }
}

export default  WaMsgModel;