import axios from 'axios';
import type { BusinessRespDto } from '../models/business'

const GET_SELF_BUSINESS_URL = process.env.REACT_APP_API_ENDPOINT+'/v1/business/self';

const BusinessApis = () => {

    const getSelfBusinessApi = async (apiKey: string) => {

        const config = {
          headers: {
            'Accept': 'application/json',
            "Authorization": "Bearer " + apiKey
            }
          };
    
        const response = await axios.get<BusinessRespDto>(GET_SELF_BUSINESS_URL, config);
        return response;
      }

      return {  
        getSelfBusinessApi
      }

}

export default BusinessApis;