import React from "react";
import { WaMsgController } from "../controllers";

import "../css/wamsg.css";

const WaMsgView = () => {
  const {
    isProgressFlag,
    isAuthorized,
    activeARM,
    armMessage,
    armButton1,
    armButton2,
    armButton3,
    handleArmMessageChange,
    handleArmButton1Change,
    handleArmButton2Change,
    handleArmButton3Change,
    handleSubmitAndActivateArm,
  } = WaMsgController();

  return (
    <>
      <div>
        {!isProgressFlag && <p>Loading...</p>}
        {isProgressFlag && !isAuthorized && <p>Not Authorized</p>}

        {/* Show active ARM if any*/}
        {isProgressFlag && isAuthorized && activeARM ? (
          <>
            <h3 key="active-message-header">
              Active WhatsApp Auto Response Message:{" "}
            </h3>
            <p key="active-message" className="p-inline">
              Message: &nbsp; {activeARM.message}
            </p>
            {activeARM.auto_response_message_button?.map((item, index) => {
              return (
                <>
                  <p key={item.auto_response_message_button_id}>
                    Option {index + 1} : &nbsp; {item.button_text}
                  </p>
                </>
              );
            })}
          </>
        ) : (
          <>
            <p>No Active WhatsApp Auto Response Message</p>
          </>
        )}

        {/* Form to create and activate new ARM */}
        {isProgressFlag && isAuthorized && (
          <>
            <h3 key="new-message-header">
              Setup a new WhatsApp Auto Response Message:
            </h3>
            <form onSubmit={handleSubmitAndActivateArm}>
              <label>Message*: &nbsp;</label>
              <textarea
                className="text-message-inline"
                wrap="soft"
                value={armMessage as string}
                onChange={handleArmMessageChange}
              />
              <br></br>
              <br></br>
              <label>Option 1: &nbsp;</label>
              <textarea
                className="text-option-inline"
                wrap="soft"
                value={armButton1 as string}
                onChange={handleArmButton1Change}
              />
              <br></br>
              <br></br>
              <label>Option 2: &nbsp;</label>
              <textarea
                className="text-option-inline"
                wrap="soft"
                value={armButton2 as string}
                onChange={handleArmButton2Change}
              />
              <br></br>
              <br></br>
              <label>Option 3: &nbsp;</label>
              <textarea
                className="text-option-inline"
                wrap="soft"
                value={armButton3 as string}
                onChange={handleArmButton3Change}
              />
              <br></br>
              <br></br>
              <input
                className="msg-submit-button"
                type="submit"
                value="Create and Activate"
              />
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default WaMsgView;
