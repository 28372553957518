import React from "react";
import { LoginViewModel } from '../view-models';
import { SingleValue } from "react-select";

const LoginController = () => {

    const { 
        isLoggedIn,
        phoneNumber, 
        otp, 
        showOtpField,
        setPhoneNumber, 
        setOtp, 
        setIsdCode, 
        generateOtp, 
        verifyOtp 
    } = LoginViewModel();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };
    
    const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {        
        setOtp(event.target.value);
    };

    const handleIsdCodeChange = (selectedOption : SingleValue<{ value: string; label: string; }> | null) => {
        if(selectedOption != null) {
            setIsdCode(selectedOption.value);  
        }
    };
    
    const handleGetOtpSubmit = (event:  React.FormEvent<HTMLFormElement>) => {
        // Send the OTP to the user's phone number
        // TODO: Handle invalid phone number, for ex. not equal to 10 digits
        event.preventDefault();
        console.log("in handleGetOtpSubmit")
        generateOtp();
    };
    
    const handleOtpSubmit = (event:  React.FormEvent<HTMLFormElement>) => {
        // Verify the OTP entered by the user
        // If the OTP is correct, log the user in
        event.preventDefault();
        console.log("in handleOtpSubmit");
        verifyOtp();
    };

    return {

        isLoggedIn,
        phoneNumber, 
        otp, 
        showOtpField,
        handlePhoneNumberChange,
        handleOtpChange,
        handleIsdCodeChange,
        handleGetOtpSubmit,
        handleOtpSubmit
    }

};

export default LoginController;

