import { useState, useEffect, useRef } from "react";
import { WaAccountRespDto } from "../models/wa";
import { WaApis } from "../apis";
import { AuthSession } from "../helper";

const CallsyWaModel = () => {
    
    const [isProgressFlag, setIsProgressFlag] = useState<boolean>(false);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [isWaAccountLinked, setIsWaAccountLinked] = useState<boolean>(false);
    const [waAccount, setWaAccount] = useState<WaAccountRespDto>();
    const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
    const isMatrixUserCreatedRef = useRef<boolean>(false);

    const {
        createMatrixUserApi,
        matrixUserExistsApi,
        getLoggedInWaAccountApi,
        createWaBridgeApi,
        loginToWaApi
    } = WaApis();

    const {getApiKey, getLoggedInUser, getLoggedInUserBusiness} = AuthSession();

    useEffect(() => {
        console.log("in useEffect for CallsyWaModel");
        // https://ultimatecourses.com/blog/using-async-await-inside-react-use-effect-hook
        (async () => {
            console.log("in async useEffect for CallsyWaModel");

            // get api key
            const apiKey = await getApiKey();
            // get user
            const user = await getLoggedInUser();
            // get business
            const business = await getLoggedInUserBusiness();
    
            console.log(apiKey);
            console.log(business);
            console.log(user);

            {/* 
                unauthorized if:
                apiKey is not available
                user does not belong to a businses
                user is not a business owner
            */} 
            
            if (apiKey && business && user && user?.user_role === "BUSINESS_OWNER") {
                setIsAuthorized(true); // authorized
            } else {
                setIsProgressFlag(true); // check complete
                return; //exit the function, isAuthorized is false
            }

            // get businessId
            const businessId = business?.business_id;
            
            // check if matrix user exists
            const responseMatrixUserExists = matrixUserExistsApi(apiKey, businessId);

            // process response
            responseMatrixUserExists.then((res) => {
                // check if response is true or false
                if (res.data === true) {
                    isMatrixUserCreatedRef.current = true; // matrix user exists
                }else {
                    // matrix user does not exist
                    setIsProgressFlag(true); // check complete
                    return; //exit the function, isAuthorized is true, isMatrixUserCreated is false
                }
            }).catch((err) => {
                console.log(err);
            });
            
            // get logged in wa account
            const responseLoggedInWaAccount = getLoggedInWaAccountApi(apiKey, businessId);

            // process response
            responseLoggedInWaAccount.then((res) => {
            
                if (res.status === 200) {
                    setIsWaAccountLinked(true); // wa account is linked
                    setWaAccount(res.data); // set wa account
                } else {
                    // for response status 204
                    setIsProgressFlag(true); // check complete
                    //exit the function, isAuthorized is true, isMatrixUserCreated is true, isWaAccountLinked is false
                    return; 
                }
            
            }).catch((err) => {
                console.log(err);
            });

            // check complete
            setIsProgressFlag(true);
            })();       
            
    }, []);

    const loginToWhatsapp = async () => {

        if (!isProgressFlag || !isAuthorized) {
            return; //exit the function, isProgressFlag is false or isAuthorized is false
        }

        // get api key
        const apiKey = await getApiKey();
        // get business
        const business = await getLoggedInUserBusiness();

        if (!apiKey || !business) {
            return; //exit the function, apiKey or business is null
        }
        
        // variable for businessId
        const businessId = business.business_id;
        
        // isMatrixUserCreated is set in useEffect
        // if false, then user needs to be created.
        if (!isMatrixUserCreatedRef.current) {
            const response = await createMatrixUserApi(apiKey, businessId);
            if (response.status === 204) {
                
                // create wa bot bridge
                // assuming both createMatrixUser and createWaBridge are successful
                // no separate status check for createWaBridge
                // TODO: Fix this
                const response = await createWaBridgeApi(apiKey, businessId);
                if (response.status === 204) {
                    isMatrixUserCreatedRef.current = true; // matrix user created
                } else {
                    console.error("Error in creating WA bridge" + response.status + " " + response.statusText);
                    return; //exit the function, error in creating WA bridge
                }

            } else {
                console.error("Error in creating matrix user" + response.status + " " + response.statusText);
                return; //exit the function, error in creating matrix user
            }

        }

        // callbackfn for loginToWaApi
        const callbackfn = (res: string) => {
            if (res != "heartbeat") {
                setQrCodeUrl(res);
            }
        }

        // isWaAccountLinked is set in useEffect
        // if false, login to WA
        if (!isWaAccountLinked) {
            console.log("loginToWaApi");
            loginToWaApi(apiKey, businessId, callbackfn);  
        }
    }

    return {
        isProgressFlag,
        isAuthorized,
        isWaAccountLinked,
        waAccount,
        qrCodeUrl,
        loginToWhatsapp
    }
}

export default CallsyWaModel;