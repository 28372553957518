import axios from 'axios';
import type { WaAccountRespDto } from '../models/wa'
import { fetchEventSource } from '@microsoft/fetch-event-source';


const CREATE_MATRIX_USER_URL = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/matrix/user`;
const MATRIX_USER_EXISTS_URL =  (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/matrix/user/exists`;
const GET_LOGGEDIN_WA_URL = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/matrix/wabb/account/loggedIn`;
const CREATE_WA_BRIDGE = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/matrix/wabb/room`;
const LOGIN_TO_WA = (businessId: number) => `${process.env.REACT_APP_API_ENDPOINT}/v1/business/${businessId}/matrix/wabb/account/login`;

const WaApis = () => {

    const createMatrixUserApi = async (apiKey: string, businessId: number) => {

        const config = {
            headers:{
              "Authorization": "Bearer " + apiKey
            }
          };

        const response = await axios.post<void>(CREATE_MATRIX_USER_URL(businessId), null, config);
        return response;
    }

    const matrixUserExistsApi = async (apiKey: string, businessId: number) => {

        const config = {
            headers:{
              "Authorization": "Bearer " + apiKey
            }
          };

        const response = await axios.get<boolean>(MATRIX_USER_EXISTS_URL(businessId), config);
        return response;
    }

    const getLoggedInWaAccountApi = async (apiKey: string, businessId: number) => {

        const config = {
            headers:{
              "Authorization": "Bearer " + apiKey
            }
          };

        const response = await axios.get<WaAccountRespDto>(GET_LOGGEDIN_WA_URL(businessId), config);
        return response;
    }

    const createWaBridgeApi = async (apiKey: string, businessId: number) => {
            
        const config = {
            headers:{
                "Authorization": "Bearer " + apiKey
            }
          };

        const response = await axios.post<void>(CREATE_WA_BRIDGE(businessId), null, config);
        return response;
    }
  
    const loginToWaApi = async (apiKey: string, businessId: number, callbackfn: (event: string) => void) => {
      //https://blog.logrocket.com/using-fetch-event-source-server-sent-events-react/
      await fetchEventSource(LOGIN_TO_WA(businessId), {
        keepalive: true,
        method: "POST",
        headers: {
          Accept: "text/event-stream",
          Authorization: "Bearer " + apiKey
        },
        onmessage(event) {
          callbackfn(event.data);
        },
        onclose() {
          console.log("Connection closed by the server");
          callbackfn(""); // set empty URL
        },
        onerror(err) {
          console.log("There was an error from server", err);
          callbackfn(""); // set empty URL
      },
    });
    

    }
    

    return {
        createMatrixUserApi,
        matrixUserExistsApi,
        getLoggedInWaAccountApi,
        createWaBridgeApi,
        loginToWaApi
    }

}

export default WaApis;