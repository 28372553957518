import React, { useState, useEffect } from "react";
import { AuthSession } from "../helper";
import { Navigate } from "react-router-dom";
import { screenMap } from "../navigation/screenMap";

const HomeView = () => {
  const { getApiKey } = AuthSession();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    async () => {
      const apiKeyInStorage = await getApiKey();
      if (apiKeyInStorage) {
        setIsLoggedIn(true);
      }
    };
  }, []);

  if (isLoggedIn) {
    return (
      // replace={true} is used to prevent the user from going back to the home page by clicking the back button
      <Navigate to={screenMap.CallsyWa} replace={true} />
    );
  } else {
    return <Navigate to={screenMap.Login} replace={true} />;
  }
};

export default HomeView;
