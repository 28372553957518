import React from "react";
import { WaMsgModel } from '../view-models';

const WaMsgController = () => {

    const {
        isProgressFlag,
        isAuthorized,
        activeARM,
        armMessage,
        armButton1,
        armButton2,
        armButton3,
        setArmMessage,
        setArmButton1,
        setArmButton2,
        setArmButton3,
        activateWaArm
    } = WaMsgModel();   

    const handleArmMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setArmMessage(event.target.value);
    }

    const handleArmButton1Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setArmButton1(event.target.value);
    }   

    const handleArmButton2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {    
        setArmButton2(event.target.value);
    }   

    const handleArmButton3Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setArmButton3(event.target.value);
    }

    const handleSubmitAndActivateArm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        activateWaArm();
    }   

    return {
        isProgressFlag,
        isAuthorized,
        activeARM,
        armMessage,
        armButton1,
        armButton2,
        armButton3,
        handleArmMessageChange,
        handleArmButton1Change,
        handleArmButton2Change,
        handleArmButton3Change,
        handleSubmitAndActivateArm
    }
}

export default WaMsgController;