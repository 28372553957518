import React from "react";
import { CallsyWaController } from "../controllers";
import "../css/callsywa.css";
import { Link } from "react-router-dom";
import { screenMap } from "../navigation/screenMap";

const CallsyWaView = () => {
  const {
    isProgressFlag,
    isAuthorized,
    isWaAccountLinked,
    waAccount,
    qrCodeUrl,
    handleLoginToWhatsapp,
  } = CallsyWaController();

  return (
    <>
      <div>
        {!isProgressFlag && <p>Loading...</p>}
        {isProgressFlag && !isAuthorized && <p>Not Authorized</p>}
        {isProgressFlag && isAuthorized && (
          <>
            {!isWaAccountLinked ? (
              <>
                <p>
                  Scan the QR Code with your WhatsApp App to link your WhatsApp
                </p>
                <form onSubmit={handleLoginToWhatsapp}>
                  <input
                    className="wa-login-button"
                    type="submit"
                    value="Login To WhatsApp"
                  />
                </form>
                <br />
                <img src={qrCodeUrl} alt="QR Code Will be Displayed Here." />
                <br />
                <p>
                  Once you login with your WhatsApp refresh this page to see the
                  information regarding the account with which you have logged
                  in
                </p>
                <p>
                  Setup a message here:{" "}
                  {<Link to={screenMap.WaMsg}>WA Message</Link>}
                </p>
              </>
            ) : (
              <>
                <p>WhatsApp Account Linked</p>
                <p>Linked Whatsapp Account ID: {waAccount?.wa_id}</p>
                <p>Linked Whatsapp Account Name: {waAccount?.wa_name}</p>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CallsyWaView;
