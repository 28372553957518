import { useState, useEffect } from "react";
import type { GenerateOtpReqDto,  VerifyOtpReqDto} from '../models/login'
import { LoginApis } from '../apis';
import { DeviceIdentifier, AuthSession } from '../helper';
import { useNavigate } from "react-router-dom";
import { screenMap } from "../navigation/screenMap";

const LoginViewModel = () => {
    
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    //set by default as this is the default selected value in the dropdown
    const [isdCode, setIsdCode] = useState<string>('+91'); 
    const [showOtpField, setShowOtpField] = useState<boolean>(false);
    
    const { getApiKey } = AuthSession();
    const {generateOtpApi, verifyOtpApi} = LoginApis();
    const {getDeviceId, getUserAgent} = DeviceIdentifier();
    const {setApiKey} = AuthSession();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("in useEffect for loginViewModel");
        (async () => {
            console.log("in async useEffect for loginViewModel");
            const apiKeyInStorage = await getApiKey();
            console.log("apiKeyInStorage: " + apiKeyInStorage);
            if (apiKeyInStorage) {
                console.log("in if apiKeyInStorage");
                setIsLoggedIn(true);
            }
        })();
    }, []);

    const generateOtp = async () => {

        const deviceIdInStorage = await getDeviceId();
        let deviceId;
        if (deviceIdInStorage) {
            deviceId = deviceIdInStorage;
        } else {
            deviceId = '';
        }

        // Send the OTP to the user's phone number
        const generateOtpReqDto : GenerateOtpReqDto = {
            isd_code: isdCode,
            phone_number: phoneNumber,
            device_id:  deviceId,
            user_agent: getUserAgent(),
            platform_type: "WEB" // hard coded to web
        }

        // make the API call
        const response = generateOtpApi(generateOtpReqDto);

        // on success set showOtpField field to true
        response.
        then(() => {
            setShowOtpField(true);
        }).catch(err => {
            {/* TODO: Handle Failure */}
            console.error(err);
            setShowOtpField(false);
        })
        
    };
    
    const verifyOtp = async () => {
        
        // If OTP is correct login
        console.log("isdCode: " + isdCode);
        console.log("phoneNumber: " + phoneNumber);
        console.log("otp: " + otp);

        const deviceIdInStorage = await getDeviceId();
        let deviceId;
        if (deviceIdInStorage) {
            deviceId = deviceIdInStorage;
        } else {
            deviceId = '';
        }
        
        // verify OTP
        const verifyOtpReqDto : VerifyOtpReqDto = {
            isd_code: isdCode,
            phone_number: phoneNumber,
            device_id: deviceId,
            user_agent: getUserAgent(),
            platform_type: "WEB", // hard coded to web
            otp: otp
        }

        // make the API call
        const response = verifyOtpApi(verifyOtpReqDto);

        // on success, get the authSession and set the API key
        response.
          then(res => {
            console.log("res.data.session_token: " + res.data.session_token);
            setApiKey(res.data.session_token); //set the API Key
            setIsLoggedIn(true); // set loggedIn to true
            // set it to false so that next time on login, the user has to enter the phone number again
            setShowOtpField(false); 
            navigate(screenMap.CallsyWa);
        }).catch(err => {
            {/* TODO: Handle Failure */}
            console.error(err);
        })
    
    };

    return {
        isLoggedIn,
        phoneNumber,
        otp,
        isdCode,
        showOtpField,
        setPhoneNumber,
        setOtp,
        setIsdCode,
        generateOtp,
        verifyOtp
    }

};

export default LoginViewModel;