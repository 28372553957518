import { UserApis, BusinessApis } from "../apis";
import { LocalStorage } from "../repository";
import { UserRespDto } from "../models/user";
import { BusinessRespDto } from "../models/business";


const AuthSession = () => {

    const API_KEY = "API_KEY";
    const LOGGED_IN_USER = "LOGGED_IN_USER";
    const LOGGED_IN_USER_BUSINESS = "LOGGED_IN_USER_BUSINESS";

    const { getSelfUserApi } = UserApis();
    const { getSelfBusinessApi } = BusinessApis();
    const { read, write } = LocalStorage();

    const setApiKey = async (apiKey: string) => {
        console.log("in setApiKey: " + apiKey);
        write(API_KEY, apiKey);
    }

    const getApiKey = async () => {
        const apiKey = await read(API_KEY);
        return apiKey;
    }

    const getLoggedInUser = async () => {

        const userInStorageJson = await read(LOGGED_IN_USER);
        let userInStorage: UserRespDto | null = null;

        // return if already in storage
        if (userInStorageJson) {
            // convert json to type of user object
            userInStorage = JSON.parse(userInStorageJson);
            return userInStorage;
        }

        // if not in storage, get from api

        // get the api key
        const apiKeyInStorage = await getApiKey();

        // if apiKey does not exist
        if(!apiKeyInStorage){
            return null;
        }

        // make the api call
        const response = await getSelfUserApi(apiKeyInStorage);
        if (response.status === 200) {
            // get the user
            userInStorage = response.data
            // save it in storage
            write(LOGGED_IN_USER, JSON.stringify(userInStorage));
        } else {
            console.error("Failed to get logged in user " + response.status + " " + response.statusText);
            return null;
        } 
        return userInStorage;
    }

    const getLoggedInUserBusiness = async () => {

        const businessInStorageJson = await read(LOGGED_IN_USER_BUSINESS);
        let businessInStorage: BusinessRespDto | null = null;

        // return if already in storage
        if (businessInStorageJson) {
            // convert json to type of user object
            businessInStorage = JSON.parse(businessInStorageJson);
            return businessInStorage;
        }
    
        // if not in storage, get from api

        // get the api key
        const apiKeyInStorage = await getApiKey();

        // if apiKey does not exist
        if(!apiKeyInStorage){
            return null;
        }

        // make the api call
        const response = await getSelfBusinessApi(apiKeyInStorage);
        
        if (response.status === 200) {
            // get the business
            businessInStorage = response.data
            // save it in storage
            write(LOGGED_IN_USER_BUSINESS, JSON.stringify(businessInStorage));
        } else {
            console.error("Failed to get logged in user business " + response.status + " " + response.statusText);
            return null;
        }

        return businessInStorage;
    
    }

    return {
        setApiKey,
        getApiKey,
        getLoggedInUser,
        getLoggedInUserBusiness
    }

}

export default AuthSession;