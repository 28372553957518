import { v4 as uuid } from 'uuid';
import { LocalStorage } from "../repository";
const { read, write } = LocalStorage();

const DeviceIdentifier = () => {

    const DEVICE_ID = "DEVICE_ID";

    const getDeviceId = async () => {

        // get deviceId from Storage
        const deviceIdInStorage = await read(DEVICE_ID);
        console.log("deviceIdInStorage: " + deviceIdInStorage);
        let generatedUUID: string | null = null;

        if (!deviceIdInStorage || deviceIdInStorage.length === 0) {
            // string is empty, set it to UUID
            generatedUUID = uuid();
            // write to storage
            write(DEVICE_ID, generatedUUID);
        } else {
            // do nothing
            console.log(" in else getDeviceId");
            generatedUUID = deviceIdInStorage;
        }
        
        // return the value
        console.log("returning deviceId: " + generatedUUID);
        return generatedUUID
    }

    const getUserAgent = () => {
        return window.navigator.userAgent;
    }

    return {
        // not returning deviceId, instead returning getDeviceId as the function will set if not available already
        getDeviceId,
        getUserAgent
    }

}

export default DeviceIdentifier;