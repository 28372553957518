import React from "react";
import Select from "react-select";
import { LoginController } from "../controllers";
import { Navigate } from "react-router-dom";
import { screenMap } from "../navigation/screenMap";

import "../css/loginview.css";

const LoginView = () => {
  const {
    isLoggedIn,
    phoneNumber,
    otp,
    showOtpField,
    handlePhoneNumberChange,
    handleOtpChange,
    handleIsdCodeChange,
    handleGetOtpSubmit,
    handleOtpSubmit,
  } = LoginController();

  const options = [{ value: "+91", label: "+91" }];

  if (isLoggedIn) {
    return (
      // if already loggedIn, navigate to CallsyWa and replace the current screen
      <Navigate to={screenMap.CallsyWa} replace={true} />
    );
  } else {
    return (
      <>
        <div>
          <>
            {!showOtpField ? (
              <>
                {" "}
                {/* Dummy element */}
                <p>
                  Login to a Callsy Account. Account should have been created on
                  the Callsy mobile app before logging in here
                </p>
                <form onSubmit={handleGetOtpSubmit}>
                  <label>Mobile Number:</label>
                  {/* if default selected changes from +91 to something else then update the same in LoginViewModel also */}
                  {/* Link to issue faced by some people - https://github.com/facebook/react/issues/6951 */}
                  <div className="phone-number-input">
                    <div className="isd-drop-down">
                      <Select
                        defaultValue={options[0]}
                        options={options}
                        onChange={handleIsdCodeChange}
                      />
                    </div>
                    <input
                      type="text"
                      value={phoneNumber as string}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                  {/* Add links to T&C and Privacy Policy here*/}
                  <p>
                    By continuing you agree with the Terms & Conditions and
                    Privacy Policy
                  </p>
                  <input className="otp-button" type="submit" value="Get OTP" />
                </form>
              </>
            ) : (
              <form onSubmit={handleOtpSubmit}>
                <p>Enter OTP sent to {phoneNumber}:</p>
                <p>Enter OTP</p>
                <input
                  type="text"
                  value={otp as string}
                  onChange={handleOtpChange}
                />
                <br />
                <br />
                <input className="otp-button" type="submit" value="Verify" />
              </form>
            )}
          </>
        </div>
      </>
    );
  }
};

export default LoginView;
