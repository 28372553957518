import { CallsyWaModel } from '../view-models';

const CallsyWaController = () => {

    const { isProgressFlag,
        isAuthorized,
        isWaAccountLinked,
        waAccount,
        qrCodeUrl,
        loginToWhatsapp
    } = CallsyWaModel();

    const handleLoginToWhatsapp = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        loginToWhatsapp();
    } 

    return {
        isProgressFlag,
        isAuthorized,
        isWaAccountLinked,
        waAccount,
        qrCodeUrl,
        handleLoginToWhatsapp
    }

}

export default CallsyWaController;